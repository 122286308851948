import React from "react"
import PropTypes from "prop-types"
import { GatsbyImage } from "gatsby-plugin-image"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faStar } from "@fortawesome/free-solid-svg-icons"

const GoogleCommentItem = ({
  slider: { name, image, time, comment, link },
}) => {
  const fiveStar = []
  for (let i = 1; i <= 5; i++) {
    fiveStar.push(<FontAwesomeIcon icon={faStar} className="mr-1.5" key={i} />)
  }
  return (
    <div className="mx-auto h-76 px-3 py-1 sm:px-4 lg:px-4 xl:px-8 2xl:px-4">
      <div className="h-full w-full rounded-lg bg-white p-6 shadow-md xl:w-80">
        <div className="flex">
          <a href={link} target="_blank" rel="noreferrer">
            <GatsbyImage image={image} alt={name} className="mr-4 h-12 w-12" />
          </a>
          <div className="truncate text-left">
            <a
              href={link}
              target="_blank"
              rel="noreferrer"
              className="truncate"
            >
              {name}
            </a>
            <p className="text-gray-500">{time}</p>
          </div>
        </div>
        <div className="mt-4 flex text-lg text-yellow-400">{fiveStar}</div>
        <p className="mt-2 line-clamp-6 text-left text-gray-700">{comment}</p>
      </div>
    </div>
  )
}

GoogleCommentItem.propTypes = {
  slider: PropTypes.object,
}

export default GoogleCommentItem
