export const sliderSetting = {
  default: {
    dots: false,
    arrows: true,
    infinite: true,
    autoplay: false,
    speed: 500,
    lazyLoad: null,
    slidesToShow: 4,
    slidesToScroll: 4,
    swipeToSlide: true,
    centerMode: false,
    centerPadding: "24px",
    variableWidth: false,
    responsive: [
      {
        breakpoint: 1536,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
        },
      },
      {
        breakpoint: 1279,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 1023,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          arrows: false,
          centerMode: true,
          centerPadding: "32px",
        },
      },
      {
        breakpoint: 639,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: false,
          centerMode: true,
          centerPadding: "32px",
        },
      },
    ],
  },
}
