import React from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import ReactMarkdown from "react-markdown"

const TagItem = ({ tag: { slug, title } }) => {
  return (
    <Link
      to={`/blog/tag/${slug}`}
      className="mr-3 rounded-lg bg-orange-400 px-2 py-1 font-medium text-white"
    >
      <div>{title}</div>
    </Link>
  )
}

const PostItem = ({ slider: { slug, cover, title, tags, excerpt } }) => {
  const tagList = tags.map((tag, index) => <TagItem key={index} tag={tag} />)
  const postUrl = `/blog/${slug}/`
  return (
    <div className="container mx-auto px-3 sm:px-4 lg:px-4 xl:px-8 2xl:px-4">
      <div className="w-full rounded-lg bg-white shadow-md xl:w-80">
        <Link to={postUrl}>
          <GatsbyImage
            image={cover.gatsbyImageData}
            alt={cover.title}
            className="z-10 mx-auto h-48 rounded-t-lg object-cover"
          />
        </Link>
        <div className="mt-5 flex px-5">{tagList}</div>
        <div className="mt-3 block h-14 overflow-hidden px-5 text-left text-xl font-bold">
          <Link to={postUrl}>{title} - 85010</Link>
        </div>
        <ReactMarkdown className="my-4 h-24 overflow-hidden px-5 text-left text-gray-500">
          {excerpt.excerpt}
        </ReactMarkdown>
        <div className="border-b bg-gray-300" />
        <div className="px-5 py-3 text-left text-gray-700">
          <Link to={postUrl}>繼續閱讀</Link>
        </div>
      </div>
    </div>
  )
}
TagItem.propTypes = {
  tag: PropTypes.object,
}
PostItem.propTypes = {
  slider: PropTypes.object,
}

export default PostItem
