import React, { useEffect, useRef, useState } from "react"
import PropTypes from "prop-types"
import Slider from "react-slick"
import { FaChevronLeft } from "@react-icons/all-files/fa/FaChevronLeft"
import { FaChevronRight } from "@react-icons/all-files/fa/FaChevronRight"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import GoogleCommentItem from "./sliderItem/GoogleCommentItem"
import MediaReportItem from "./sliderItem/MediaReportItem"
import PostItem from "./sliderItem/PostItem"
import { arrowData } from "../libs/arrow.js"
import { sliderSetting } from "../libs/sliderSetting"

const concatString = stringList => {
  return stringList.reduce(
    (prevString, curentString) => prevString + " " + curentString,
  )
}

const Arrow = ({ arrowStyle, onClick, className, icon, position }) => {
  const { hasOuter, outerClass, iconClass, iconStyle } = arrowStyle

  const arrow = React.createElement(icon, {
    className: concatString([iconClass, className, position]),
    style: iconStyle,
    onClick: onClick,
  })
  const arrowWithOuter = React.createElement(
    "div",
    {
      className: concatString([outerClass, position]),
      onClick: onClick,
    },
    React.createElement(icon, { className: iconClass, style: iconStyle }),
  )
  const content = hasOuter ? arrowWithOuter : arrow

  return <>{content}</>
}

const ArrowSlider = ({ section, sliders, arrow, setting }) => {
  const sliderRef = useRef(null)
  const [currentSlide, setCurrentSlide] = useState(0)
  const [slideToShow, setSlideToShow] = useState(0)
  useEffect(() => {
    if (slideToShow === 3) {
      const pointer = sliderRef.current
      pointer.slickGoTo(0)
    }
  }, [slideToShow])
  const setInit = () => {
    const lengthOfSlideToShow = sliderRef.current.innerSlider.props.slidesToShow
    setSlideToShow(lengthOfSlideToShow)
  }

  let Item
  switch (section) {
    case "googleComment":
      Item = GoogleCommentItem
      break
    case "mediaReport":
      Item = MediaReportItem
      break
    case "post":
      Item = PostItem
      break
    default:
      return null
  }

  let iconNext
  let iconPrev
  let arrowStyle
  switch (arrow) {
    case "roundedBackground":
      arrowStyle = arrowData[arrow]
      iconNext = FaChevronRight
      iconPrev = FaChevronLeft
      break
    case "bigArrow":
      arrowStyle = arrowData[arrow]
      iconNext = FaChevronRight
      iconPrev = FaChevronLeft
      break
    default:
      return null
  }

  const {
    dots,
    arrows,
    infinite,
    autoplay,
    speed,
    lazyLoad,
    slidesToShow,
    slidesToScroll,
    swipeToSlide,
    centerMode,
    centerPadding,
    variableWidth,
    responsive,
  } = sliderSetting[setting]

  const settings = {
    dots: dots,
    arrows: arrows,
    infinite: infinite,
    autoplay: autoplay,
    speed: speed,
    lazyLoad: lazyLoad,
    slidesToShow: slidesToShow,
    slidesToScroll: slidesToScroll,
    initialSlide: currentSlide,
    prevArrow: (
      <Arrow
        arrowStyle={arrowStyle}
        icon={iconPrev}
        position={arrowStyle.prev.position}
      />
    ),
    nextArrow: (
      <Arrow
        arrowStyle={arrowStyle}
        icon={iconNext}
        position={arrowStyle.next.position}
      />
    ),
    centerMode: centerMode,
    centerPadding: centerPadding,
    swipeToSlide: swipeToSlide,
    variableWidth: variableWidth,
    afterChange: current => {
      setCurrentSlide(current)
    },
    onReInit: setInit,
    responsive: responsive,
  }

  const sliderList = sliders.map((slider, index) => {
    return React.createElement(Item, {
      slider: slider,
      key: index,
    })
  })

  return (
    <Slider {...settings} ref={sliderRef} className="lg:px-4">
      {sliderList}
    </Slider>
  )
}

Arrow.propTypes = {
  onClick: PropTypes.func,
  style: PropTypes.object,
  className: PropTypes.string,
  arrowStyle: PropTypes.object,
  icon: PropTypes.func,
  position: PropTypes.string,
}

ArrowSlider.propTypes = {
  section: PropTypes.string,
  sliders: PropTypes.array,
  arrow: PropTypes.string,
  setting: PropTypes.string,
}

export default ArrowSlider
