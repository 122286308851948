export const arrowData = {
  roundedBackground: {
    usage: "DivorceLanding",
    hasOuter: true,
    outerClass:
      "absolute flex h-8 w-8 cursor-pointer items-center justify-center rounded-full bg-gray-200 p-1 text-gray-400 duration-300 ease-in-out hover:bg-gray-300 hover:text-gray-500 active:bg-gray-300 active:text-gray-500 lg:h-12 lg:w-12 lg:p-3",
    iconClass: "text-gray-500 sm:text-xl",
    iconStyle: "",
    next: {
      icon: "FaArrowRight",
      position: "-right-20 top-32",
    },
    prev: {
      icon: "FaArrowLeft",
      position: "-left-20 top-32",
    },
  },
  bigArrow: {
    hasOuter: false,
    iconClass: "",
    iconStyle: {
      color: "gray",
      width: "2.428rem",
      height: "4rem",
    },
    next: {
      icon: "FaChevronRight",
      position: "",
    },
    prev: {
      icon: "FaChevronLeft",
      position: "",
    },
  },
}
