import React from "react"
import PropTypes from "prop-types"
import { GatsbyImage } from "gatsby-plugin-image"
import ReactMarkdown from "react-markdown"

const MediaReportItem = ({ slider: { title, url, source, date, image } }) => {
  return (
    <div className="mx-auto px-3 sm:px-4 lg:px-4 xl:px-8 2xl:px-4">
      <div className="w-full rounded-lg bg-white shadow-md xl:w-80">
        <GatsbyImage
          image={image}
          alt={title}
          className="z-10 mx-auto h-48 rounded-t-lg object-cover"
        />
        <div className="my-4">
          <p className="px-5 text-left leading-normal text-gray-500">
            {date}．{source}
          </p>
        </div>
        <ReactMarkdown className="my-4 h-24 overflow-hidden px-5 text-left text-xl">
          {title}
        </ReactMarkdown>
        <div className="border-b bg-gray-300" />
        <div className="px-5 py-3 text-left text-gray-700">
          <a href={url} target="_blank" rel="noreferrer">
            繼續閱讀
          </a>
        </div>
      </div>
    </div>
  )
}

MediaReportItem.propTypes = {
  slider: PropTypes.object,
}

export default MediaReportItem
